<script>
  import layoutError from "@/components/art/LayoutError.vue";
  export default {
    components: { layoutError },
    methods: {
      onSubmit() {
        this.$router.push("/login").catch(() => {});
      },
    },
  };
</script>

<template>
  <layoutError @onSubmit="onSubmit">
    <template v-slot:title>
      Lo sentimos, tu sesión caducó.
    </template>
    <template v-slot:body>
      Por favor, inicie sesión nuevamente.
    </template>
  </layoutError>
</template>
